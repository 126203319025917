import styled from 'styled-components';

import responsive from '../../../util/responsive';

import colors from '../../../styles/colors';

export const Container = styled.div`
  background: #f9f9f9;
  border: 1px solid #dedfe0;

  padding: 10px 15px 20px;

  & > span {
    font-size: 22px;
    font-weight: 600;
  }

  ul {
    margin-top: 10px;

    display: grid;
    grid-template-columns: 1fr 1fr;

    ${responsive.untilBigTablet} {
      grid-template-columns: 1fr;
    }

    grid-row-gap: 10px;
    grid-column-gap: 20px;

    li {
      display: flex;

      color: ${colors.blackText};

      & > h3 {
        font-weight: normal;
        font-size: 15px;
        line-height: 21px;
      }

      svg {
        flex-shrink: 0;

        margin-top: 4px;
        margin-right: 10px;
      }
    }
  }
`;
