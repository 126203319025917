import React from 'react';
import PropTypes from 'prop-types';

import { FaCheck } from 'react-icons/fa';

import { Container } from './styles';

import colors from '../../../styles/colors';

export default function LearnContainer({ learnings }) {
  function renderLearning(learning) {
    return (
      <li key={learning}>
        <FaCheck size={15} color={colors.redAction} />
        <h3>{learning}</h3>
      </li>
    );
  }

  return (
    <Container>
      <span>O que você aprenderá</span>
      <ul>{learnings.map(learning => renderLearning(learning))}</ul>
    </Container>
  );
}

LearnContainer.propTypes = {
  learnings: PropTypes.arrayOf(PropTypes.string).isRequired,
};
