import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const Container = styled.div`
  margin-top: 30px;

  display: flex;
  flex-direction: column;

  & > span {
    font-size: 22px;
    font-weight: 600;

    margin: 10px 0;
  }

  p:not(:first-child) {
    margin-top: 10px;
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const ParagraphWrapper = styled.div`
  /* display: none; */

  max-height: 0;
  transition: max-height 0.3s ease-out;
  overflow: hidden;

  &.active {
    max-height: 1500px;
    transition: max-height 0.5s ease-in;
  }
`;

export const ExpandButton = styled.button.attrs({ type: 'button' })`
  text-align: left;

  color: ${colors.redAction};
  font-weight: 600;

  border: 0;

  transition: transform 0.2s;
  &:hover {
    transform: scaleY(1.2);
  }

  ${props =>
    props.expanded
      ? css`
          padding: 10px;
          background: none;
        `
      : css`
          margin-top: -35px; /*-35*/
          padding-top: 40px;

          background: linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.95),
            #fff
          );
        `}
`;
