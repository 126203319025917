import React from 'react';
import PropTypes from 'prop-types';

import {
  FaFileVideo,
  FaFile,
  FaFileAlt,
  FaClock,
  FaMobileAlt,
} from 'react-icons/fa';

import { Title, List, Item } from './styles';

import colors from '../../../../styles/colors';

export default function IncludesContainer({ items }) {
  function renderItem(item) {
    const { icon, label } = item;

    let Icon;
    switch (icon) {
      case 'FaFileVideo': {
        Icon = FaFileVideo;
        break;
      }
      case 'FaFile': {
        Icon = FaFile;
        break;
      }
      case 'FaFileAlt': {
        Icon = FaFileAlt;
        break;
      }
      case 'FaClock': {
        Icon = FaClock;
        break;
      }
      case 'FaMobileAlt': {
        Icon = FaMobileAlt;
        break;
      }
      default:
        Icon = FaFile;
    }

    return (
      <Item key={label}>
        <Icon size={13} color={colors.lightGrayText} />
        <span>{label}</span>
      </Item>
    );
  }

  return (
    <>
      <Title>Este curso inclui</Title>
      <List>{items.map(item => renderItem(item))}</List>
    </>
  );
}

IncludesContainer.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};
