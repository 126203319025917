import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ReactPlayer from 'react-player';

import { Container, ParagraphWrapper, ExpandButton } from './styles';

const maximumParagraphs = 2;

export default function DescriptionContainer({ video, description }) {
  const [expanded, setExpanded] = useState(false);
  const [explicit, setExplicit] = useState([]);
  const [implicit, setImplicit] = useState([]);

  function toggleExpand() {
    setExpanded(!expanded);
  }

  useEffect(() => {
     const paragraphs = description.match(/<p>(.*?)<\/p>/g);

     setExplicit(paragraphs.slice(0, maximumParagraphs))
     setImplicit(paragraphs.splice(maximumParagraphs))
  }, [description])

  function renderParagraphs() {
    return (
      <>
        {explicit.map((paragraph, index) => (
          <p
            dangerouslySetInnerHTML={{ __html: paragraph }}
            key={String(index)}
          />
        ))}

        <ParagraphWrapper className={expanded ? 'active' : ''}>
          <p></p>
          {implicit.map((paragraph, index) => (
            <p
              dangerouslySetInnerHTML={{ __html: paragraph }}
              key={String(index)}
            />
          ))}
        </ParagraphWrapper>

        {implicit.length > 0 && (
          <ExpandButton expanded={expanded} onClick={toggleExpand}>
            {expanded ? '- Ler menos' : '+ Ler mais'}
          </ExpandButton>
        )}
      </>
    );
  }

  return (
    <Container>
      <span>Descrição</span>
      {renderParagraphs()}
    </Container>
  );
}

DescriptionContainer.propTypes = {
  description: PropTypes.string.isRequired,
};
