import React from 'react';

import {
  FaFacebookSquare,
  FaLinkedin,
  FaYoutube,
  FaInstagram,
} from 'react-icons/fa';

import {
  Footer,
  SectionWrapper,
  Sections,
  Section,
  Logo,
  SocialIcons,
  CopyWriting,
} from './styles';

export default function FooterWrapper() {
  return (
    <Footer>
      <SectionWrapper>
        <Sections>
          <Section>
            <p>Redes Sociais</p>
            <ul>
              <li>
                <Logo />
              </li>
              <li>
                <SocialIcons>
                  <FaFacebookSquare size={32} />
                  <FaLinkedin size={32} />
                  <FaYoutube size={32} />
                  <FaInstagram size={32} />
                </SocialIcons>
              </li>
              <li>contato@afiliart.com</li>
              <li>comercial@afiliart.com</li>
            </ul>
          </Section>
          <Section>
            <p>Corporativo</p>
            <ul>
              <li>Afiliart Empreendimentos Digitais</li>
              <li>CNPJ: 25.213.248/0001-61</li>
              <li>Telefone: +55 16 99719-1964</li>
              <li>
                Rua Major José Inácio, 1942 - Centro - CEP: 13560-160, São
                Carlos - SP
              </li>
            </ul>
          </Section>
          <Section>
            <p>Suporte</p>
            <ul>
              <li>
                <a href="http://afiliart.com/comunidade/">Comunidade</a>
              </li>
              <li>
                <a href="http://membros.afiliart.com">Área de Membros</a>
              </li>
              <li>
                <a href="http://afiliart.com/politicas-de-privacidade">
                  Políticas de Privacidade
                </a>
              </li>
              <li>
                <a href="http://afiliart.com/afiliados">
                  Afiliados
                </a>
              </li>
            </ul>
          </Section>
          <Section>
            <p>Aprenda conosco</p>
            <ul>
              <li>
                <a href="https://afiliart.com/blog/">
                  Blog
                </a>
              </li>
              <li>
                <a href="https://afiliart.com/cursos/">
                  Cursos
                </a>
              </li>
              <li>
                <a href="https://afiliart.com/materiais/">
                  Apostilas
                </a>
              </li>
            </ul>
          </Section>
        </Sections>
      </SectionWrapper>
      <CopyWriting>
        <p>© · Afiliart. Todos os direitos reservados.</p>
      </CopyWriting>
    </Footer>
  );
}
