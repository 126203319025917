import React, { useState, useEffect, useRef } from 'react';

import {
  Container,
  MobileAction,
  CoursePreview,
  Preview,
  Content,
  ActionButton,
} from './styles';

import sizes from '../util/sizes';

import IncludesContainer from './IncludesContainer';

export default function Wrapper({
  thumbnail,
  price,
  redirect,
  includes,
  warranty,
}) {
  const [sticky, setSticky] = useState(false);
  const [bottomLimit, setBottomLimit] = useState({ reached: false, value: -1 });
  const [sizing, setSizing] = useState(false);

  const container = useRef();
  const content = useRef();
  const picture = useRef();

  const [stickyProps, setStickyProps] = useState({});

  useEffect(() => {
    function calculateStickyProps() {
      if (!container.current || !content.current) return;

      setStickyProps({
        offset: picture.current.offsetTop + picture.current.clientHeight,
        limit:
          container.current.offsetTop +
          container.current.clientHeight -
          content.current.clientHeight -
          20,
      });
    }

    let dimensionTimer;
    function updateDimensions() {
      if (dimensionTimer) window.clearTimeout(dimensionTimer);

      dimensionTimer = window.setTimeout(() => calculateStickyProps(), 300);
    }

    let observerInstance = null;

    try {
      /**
       * Tries to use ResizeObserver API, some browsers support it, some don't.
       */
      const observer = new ResizeObserver(updateDimensions);
      observer.observe(container.current);

      observerInstance = observer;
    } catch (_) {
      console.log(
        'ResizeObserver is not defined. Trying an alternative solution...'
      );

      window.addEventListener('resize', updateDimensions);
      updateDimensions();
    }

    return () => {
      if (observerInstance) observerInstance.disconnect();
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  useEffect(() => {
    const { offset, limit } = stickyProps;
    setSizing(false);

    let hasScrolled = false;

    function update() {
      // Ignore Mobile
      if (window.innerWidth < sizes.changePreviewAt) return;

      if (!container.current || !content.current) return;

      if (window.scrollY > offset) setSticky(true);
      else setSticky(false);

      setBottomLimit({
        reached: window.scrollY >= limit,
        value: limit,
      });
    }

    const scrollUpdater = setInterval(() => {
      if (!hasScrolled) return;
      hasScrolled = false;

      update();
    }, 20);

    function scrollListener() {
      hasScrolled = true;
    }

    update();

    window.addEventListener('scroll', scrollListener);

    return () => {
      clearInterval(scrollUpdater);
      window.removeEventListener('scroll', scrollListener);
    };
  }, [stickyProps]);

  function handleBuy() {
    window.location.href = redirect;
  }

  return (
    <Container ref={container}>
      <MobileAction>
        <span className="price">{price}</span>
        <a href={redirect} target="_blank" className="buy-now">
          Comprar agora
        </a>
      </MobileAction>
      <CoursePreview>
        <Preview ref={picture}>
          <img className="picture" src={thumbnail} alt="Preview" />
        </Preview>
        <Content
          ref={content}
          sticky={sticky && !sizing}
          bottomLimit={bottomLimit}
        >
          <span className="price">{price}</span>
          <ActionButton href={redirect} target="_blank" className="buy-now">
            Comprar agora
          </ActionButton>
          <span className="warranty">{warranty}</span>
          <IncludesContainer items={includes} />
        </Content>
      </CoursePreview>
    </Container>
  );
}
