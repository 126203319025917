import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { MdDehaze } from 'react-icons/md';

import Dropdown from './Dropdown';

import 'react-slidedown/lib/slidedown.css';

import {
  Header,
  Navbar,
  NavbarWrapper,
  Logo,
  ToggleMenuButton,
  Items,
  Item,
} from './styles';

export default function HeaderWrapper({ fixed, hasOptions }) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  function handleToggleMenu() {
    setToggleMenu(!toggleMenu);
  }

  useEffect(() => {
    if (!fixed) return;

    let hasScrolled = false;

    function update() {
      setToggleMenu(false);

      if (window.scrollY > 60) {
        setScrolled(true);
        return;
      }
      setScrolled(false);
    }

    const scrollUpdater = setInterval(() => {
      if (!hasScrolled) return;
      hasScrolled = false;

      update();
    }, 50);

    function scrollListener() {
      hasScrolled = true;
    }

    update();

    window.addEventListener('scroll', scrollListener);

    return () => {
      clearInterval(scrollUpdater);
      window.removeEventListener('scroll', scrollListener);
    };
  }, [fixed]);

  return (
    <>
      <Header fixed={fixed} scrolled={scrolled}>
        <Navbar>
          <NavbarWrapper centered={!hasOptions}>
            <Link to="/">
              <Logo />
            </Link>
            {hasOptions && (
              <ToggleMenuButton onClick={handleToggleMenu}>
                <MdDehaze size={30} color="#fff" />
              </ToggleMenuButton>
            )}
          </NavbarWrapper>
          {hasOptions && (
            <Dropdown>
              <Items toggle={toggleMenu}>
                <Item>
                  <a href="https://afiliart.com/sobre-nos">Sobre nós</a>
                </Item>
                <Item>
                  <Link to="/">Cursos</Link>
                </Item>
                <Item>
                  <a href="https://afiliart.com/materiais">Materiais</a>
                </Item>
                <Item>
                  <a href="https://afiliart.com/ferramentas">Ferramentas</a>
                </Item>
                <Item>
                  <a href="https://afiliart.com/blog">Blog</a>
                </Item>
                <Item>
                  <a href="https://afiliart.com/contato/orcamento">Contato</a>
                </Item>
                <Item className="header-button">
                  <a href="https://membros.afiliart.com/">Login</a>
                </Item>
              </Items>
            </Dropdown>
          )}
        </Navbar>
      </Header>
    </>
  );
}

HeaderWrapper.defaultProps = {
  fixed: true,
  hasOptions: true,
};

HeaderWrapper.propTypes = {
  fixed: PropTypes.bool,
  hasOptions: PropTypes.bool,
};
