import React from 'react';

import SEO from '../SEO';
import Header from '../Header';
import Footer from '../Footer';

import { Wrapper, Container } from './styles';

export default function Layout({
  children,
  seoProps = {},
  headerOptions = {},
}) {
  return (
    <>
      <SEO {...seoProps} />

      <Wrapper>
        <Header {...headerOptions} />
        <Container>{children}</Container>
        <Footer />
      </Wrapper>
    </>
  );
}
