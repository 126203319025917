export const fromSeconds = fullSeconds => {
  const seconds = fullSeconds % 60;
  const minutes = Math.floor(fullSeconds / 60) % 60;
  const hours = Math.floor(fullSeconds / (60 * 60));

  const pad = n => String(n).padStart(2, '0');

  let formatBuilder = '';

  if (hours) formatBuilder += `${pad(hours)} `;
  formatBuilder += `${pad(minutes)} `;
  formatBuilder += `${pad(seconds)}`;

  return formatBuilder.trim().replace(/ /g, ':');
};
