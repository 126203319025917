import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Title = styled.span`
  margin-bottom: 5px;

  font-size: 15px;
  color: ${colors.lightGrayText};
`;

export const List = styled.ul`
  display: grid;

  font-size: 13px;
  color: ${colors.lightGrayText};

  grid-template-columns: 1fr;
  grid-row-gap: 6px;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`;
