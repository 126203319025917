import styled, { css } from 'styled-components';
import { darken } from 'polished';

import sizes from '../util/sizes';

import colors from '../../../styles/colors';

export const Container = styled.div`
  ${sizes.onChangePreview} {
    order: 2;
  }
`;

export const MobileAction = styled.div`
  z-index: 2;

  position: fixed;
  bottom: 0;

  padding: 10px 20px;
  border-top: 1px solid #4a4a4a;
  background: #2b2b2b;

  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: 'Open Sans', sans-serif;

  ${sizes.onChangePreview} {
    display: none;
  }

  .price {
    white-space: nowrap;

    font-weight: bold;
    font-size: 22px;
    line-break: inherit;
    margin-right: 20px;

    width: max-content;

    color: #fff;
  }

  a.buy-now {
    font: 16px 'Open Sans', sans-serif;
    color: #fff;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 0;
    background: ${colors.redAction};
  }
`;

export const CoursePreview = styled.div`
  margin: 0 auto;
  padding: 5px;

  max-width: 367px;
  max-height: min-content;

  background: #fff;

  border-radius: 5px;
  border: 1px solid #dedfe0;

  ${sizes.onChangePreview} {
    margin: 15px;
    margin-top: -255px;
  }

  & > button {
    font-family: 'Open Sans', sans-serif;
  }

  .picture {
    width: 100%;
  }
`;

export const Preview = styled.div`
  background: #de2540;
  width: 100%;
  max-height: min-content;

  border-radius: 4px;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px;

  .price {
    color: #494949;

    font-weight: bold;
    font-size: 36px;

    transition: font-size 0.2s;
  }

  .warranty {
    margin: 10px 0;

    font-size: 12px;

    width: 100%;
    text-align: center;
  }

  .coupoun {
    color: ${colors.redAction};
    text-align: center;

    margin: 20px auto 0;

    transition: 0.2s;

    &:hover {
      color: ${darken(0.2, colors.redAction)};
    }
  }

  background: #fff;
  transition: background 1s;

  ${sizes.onChangePreview} {
    ${({ sticky, bottomLimit }) =>
      sticky &&
      css`
        position: fixed;
        top: 10px;
        background: #f7f7f7;

        margin-left: -5px;
        padding: 25px;

        border-radius: 5px;
        border: 1px solid #dedfe0;

        max-width: 365px;
        width: 100%;

        .price {
          font-size: 43px;
        }

        button {
          font-size: 25px;
        }

        ${bottomLimit.reached &&
          css`
            position: absolute;
            top: ${bottomLimit.value}px;
          `}
      `}
  }
`;

export const ActionButton = styled.a`
  width: 100%;
  color: #fff;
  padding: 20px 30px;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  transition: 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    transform: scale(1.02);
  }

  &.add-to-cart {
    margin-top: 10px;

    background: #fff;
    border: 1px solid rgba(14, 14, 14, 0.2);

    color: #686f7a;

    &:hover {
      color: ${darken(0.2, '#686f7a')};
    }
  }

  &.buy-now {
    margin-top: 10px;

    border: 0;
    background: ${colors.redAction};

    &:hover {
      background: ${darken(0.2, colors.redAction)};
    }
  }
`;
