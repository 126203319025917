import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  background: linear-gradient(-67deg, #030303, #0f0f0f);
`;

export const Container = styled.div`
  margin-top: 60px;

  background: linear-gradient(-67deg, #030303, #0f0f0f);
`;
