import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function RequirementsContainer({ requirements }) {
  const noRequirements =
    requirements.length <= 0 || requirements[0] === '$none';

  return (
    <Container>
      <span className="title">Para quem é este curso</span>
      {noRequirements ? (
        <span>
          Este curso não contém requisitos. Ou seja, pode ser feito por qualquer
          pessoa!
        </span>
      ) : (
        <ul>
          {requirements.map(requirement => (
            <li key={requirement}>
              <span>{requirement}</span>
            </li>
          ))}
        </ul>
      )}
    </Container>
  );
}

RequirementsContainer.propTypes = {
  requirements: PropTypes.arrayOf(PropTypes.string).isRequired,
};
