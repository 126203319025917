import styled from 'styled-components';

import responsive from '../../util/responsive';
import sizes from './util/sizes';

import colors from '../../styles/colors';

const background = '/images/background.svg';

export const Container = styled.div``;

export const CourseSection = styled.div`
  background-image: url(${background}), linear-gradient(to right, #191919, #000);
  background-repeat: no-repeat;
  background-size: cover;
`;

export const PresentationContainer = styled.div`
  max-width: ${sizes.containerMaxWidth}px;
  margin: 0 auto;
`;

export const ActionRow = styled.div`
  background: none;

  display: flex;
  justify-content: center;

  font-size: 15px;
  padding: 12px 15px;

  ${responsive.onTablet} {
    justify-content: flex-end;
  }

  ${responsive.minQuery(sizes.containerMaxWidth)} {
    font-size: 17px;
  }

  ${sizes.onChangePreview} {
    margin-bottom: 20px;
  }

  a {
    display: flex;
    align-items: center;

    color: #fff;

    svg {
      margin-right: 10px;
    }
  }
`;

export const CoursePresentation = styled.div`
  max-width: ${sizes.leftContainerMaxWidth}px;
  margin-top: 20px;

  padding: 56px 30px;

  font-family: 'Open Sans', sans-serif;

  h1 {
    font-size: 2.9em;
    font-weight: bold;

    ${responsive.untilTablet} {
      font-size: 2em;
    }
  }

  h2 {
    margin-top: 1em;

    font-size: 1.3em;
    font-weight: normal;
    line-height: 27px;
  }
`;

export const CourseStats = styled.div`
  margin-top: 10px;
`;

export const CourseMade = styled.div`
  margin-top: 30px;

  & > :nth-child(n + 2) {
    margin-left: 10px;
  }
`;

export const CourseInfo = styled.div`
  width: 100%;

  padding: 30px 0;

  color: ${colors.blackText};
  background: #fff;
  font-family: 'Open Sans', sans-serif;

  & > .wrapper {
    display: flex;

    flex-direction: column;
    justify-content: center;

    margin: 0 auto;

    ${sizes.onChangePreview} {
      max-width: ${sizes.containerMaxWidth}px;

      flex-direction: row;

      & > .desktop-to--left {
        order: 1;
      }
    }
  }
`;

export const InfoContainer = styled.div`
  padding: 15px;

  flex-shrink: 0;

  ${sizes.onChangePreview} {
    max-width: ${sizes.leftContainerMaxWidth}px;
  }
`;

export const WarrantyContainer = styled.div`
  margin: 20px;

  display: flex;
  flex-direction: column;

  ${responsive.onBigTablet} {
    flex-direction: row;
  }

  align-items: center;

  img {
    margin: 10px 15px 10px 0;
  }

  .warranty-info {
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 20px;

      margin-bottom: 10px;
    }

    h5 {
      font-size: 14px;
    }
  }
`;

export const CertifiedContainer = styled.div`
  margin: 20px;

  display: flex;
  flex-direction: column;

  ${responsive.onBigTablet} {
    flex-direction: row;
  }

  align-items: center;

  img {
    width: 300px;

    margin: 10px 0px 10px 15px;
  }

  .certified-info {
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 20px;

      margin-bottom: 10px;
    }

    h5 {
      font-size: 14px;
    }
  }
`;

export const TestimonyContainer = styled.div`
 margin-left:10vw;
  display: flex;
  flex-direction: column;
width:100%;
justify-content:center;

  .testimony-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items:center;
    justify-content:flex-start;
    h4 {
      font-size: 20px;
      text-align:center;
      margin-bottom: 10px;
      width: 80%;
    }
    .img-testmony{
      margin: 0 0 4% 0;
      width: 40%;
      box-shadow: 5px 5px 1px #35bdad;
      margin-right: 20px;
   }   
`;
