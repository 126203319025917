import responsive from '../styles/responsive';

const onDesktop = responsive.query(responsive.desktopMin);
const onTablet = responsive.query(responsive.tabletMin);
const onBigTablet = responsive.query(responsive.bigTabletMin);
const onFull = responsive.query(responsive.fullMin);

const untilDesktop = responsive.maxQuery(responsive.desktopMin);
const untilTablet = responsive.maxQuery(responsive.tabletMin);
const untilBigTablet = responsive.maxQuery(responsive.bigTabletMin);
const untilFull = responsive.maxQuery(responsive.fullMin);

export default {
  onDesktop,
  onTablet,
  onBigTablet,
  onFull,
  untilDesktop,
  untilTablet,
  untilBigTablet,
  untilFull,
  minQuery: responsive.query,
  maxQuery: responsive.maxQuery,
};
