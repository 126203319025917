import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 30px;

  display: flex;
  flex-direction: column;

  .title {
    font-size: 22px;
    font-weight: 600;

    margin: 10px 0;
  }

  ul {
    margin-left: 20px;

    list-style: disc;

    & > li:not(:first-child) {
      margin-top: 10px;
    }
  }
`;
