import styled, { css } from 'styled-components';

import responsive from '../../../util/responsive';
import colors from '../../../styles/colors';

export const Container = styled.div`
  margin-top: 30px;

  display: flex;
  flex-direction: column;

  .length {
    margin-left: 20px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  * {
    margin-left: 30px;
  }

  .title {
    font-size: 22px;
    font-weight: 600;

    margin: 10px 0;
  }

  .total-hours {
    color: #505763;
  }

  & > div {
    ${responsive.untilTablet} {
      display: flex;

      align-items: last baseline;

      flex-direction: column;

      flex-shrink: 0;

      margin-bottom: 10px;
    }
  }
`;

export const ExpandButton = styled.button.attrs({
  type: 'button',
})`
  background: none;
  border: none;

  color: rgb(0, 119, 145);

  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 15px;
`;

export const ModuleList = styled.ul`
  & > li:not(:first-child) {
    margin-top: 5px;
  }
`;

export const ModuleButton = styled.button.attrs(props => ({
  type: 'button',
  className: props.expanded ? 'active' : '',
}))`
  background: #f9f9f9;
  border: 1px solid #e8e9eb;
  color: #444;

  cursor: pointer;

  padding: 10px 30px 10px 22px;

  width: 100%;

  text-align: left;
  outline: none;
  font-size: 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font: 15px 'Open Sans', sans-serif;
  font-weight: 600;

  & > div {
    display: flex;
    align-items: center;
  }

  .title-toggle {
    font-size: 18px;
    color: ${colors.redAction};

    margin-right: 7px;
  }

  .title-text {
    color: #505763;
  }

  transition: 0.2s;
  ${responsive.onDesktop} {
    &:hover {
      transform: scale(1.02) translateY(-5px);
      .title-text {
        color: ${colors.redAction};
      }
    }
  }

  &.active {
    transform: scale(1.02);
  }
`;

export const Lectures = styled.div`
  ${props =>
    props.expanded
      ? css`
          display: inline;
        `
      : css`
          display: none;
        `}
  overflow: hidden;

  ul li.lecture {
    padding: 12px 30px 12px 43px;
    background: #fff;
    border: 1px solid #e8e9eb;
    border-top: none;

    display: flex;
    justify-content: space-between;

    .title {
      font-weight: normal;
      font-size: 14px;
      color: #505763;
    }

    & > div {
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;

        opacity: 0.3;
      }
    }
  }
`;
