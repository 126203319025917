import styled from 'styled-components';

import responsive from '../../util/responsive';

const logo = '/images/logo.svg';

export const Footer = styled.footer`
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #fff;
`;

export const SectionWrapper = styled.div`
  background: #191919;

  padding: 8% 0;
  justify-content: center;

  ${responsive.onBigTablet} {
    padding: 8% 100px;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
`;

export const Sections = styled.div`
  margin: 0 auto;
  padding: 0;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  ${responsive.onTablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${responsive.onBigTablet} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${responsive.onDesktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const Section = styled.div`
  padding: 0 15px;

  width: 100%;
  max-width: fit-content;

  p {
    margin-bottom: 1rem;

    font-weight: bold;
    text-align: left;
  }

  ul {
    margin-bottom: 1rem;
    padding-left: 40px;

    ${responsive.onDesktop} {
      padding-left: 0;
    }

    list-style: none;

    li,
    li a {
      font-weight: 400;
      line-height: 24px;
    }
  }
`;

export const Logo = styled.img.attrs({
  src: logo,
  alt: 'Home',
})`
  width: 120px;
`;

export const SocialIcons = styled.div`
  padding: 9px 0;

  display: flex;
  align-items: center;

  svg {
    color: #fff;

    & + svg {
      margin-left: 18px;
    }
  }
`;

export const CopyWriting = styled.div`
  padding: 4% 0;

  background: #000;

  font-weight: 400;
  text-align: center;
`;
