import React, { useMemo } from 'react';
import { graphql } from 'gatsby';

import { formatPrice } from '../../util/format';
import { fromSeconds } from '../../util/time';

import {
  Container,
  CourseSection,
  PresentationContainer,
  CoursePresentation,
  CourseInfo,
  InfoContainer,
  WarrantyContainer,
  CertifiedContainer,
  TestimonyContainer,
} from './styles';

import Layout from '../../components/Layout';

// import CourseRating from './CourseRating';
import CoursePreview from './CoursePreview';
import LearnContainer from './LearnContainer';
import RequirementsContainer from './RequirementsContainer';
import DescriptionContainer from './DescriptionContainer';
import CurriculumContainer from './CurriculumContainer';

import GlobalStyle from '../../styles/global';

export default function Details({ data }) {
  const {
    slug,
    title,
    subtitle,
    price,
    video,
    learnings,
    requirements,
    warranty,
    thumbnail,
    redirect: {
      internal: { content: redirect },
    },
    description: {
      childMarkdownRemark: { html: description },
    },
    curriculum: json_curriculum,
    includes: json_includes,
  } = data.course;

  const formattedPrice = useMemo(() => formatPrice(price / 100), [price]);
  const thumbnailURL = useMemo(() => `https:${thumbnail.file.url}`, [
    thumbnail.file.url,
  ]);

  const includes = useMemo(
    () => json_includes.map(e => JSON.parse(e.internal.content)),
    [json_includes]
  );

  const curriculum = useMemo(() => {
    let seconds = 0;
    let lectureAmount = 0;

    const modules = json_curriculum
      .map(e => JSON.parse(e.internal.content))
      .map(module => {
        module.seconds = 0;

        module.lectures = module.lectures.map(lecture => {
          seconds += lecture.seconds;
          module.seconds += lecture.seconds;

          lecture.formattedDuration = fromSeconds(lecture.seconds);

          lectureAmount += 1;
          return lecture;
        });

        module.formattedDuration = fromSeconds(module.seconds);

        module.expanded = false;
        return module;
      });

    return {
      lectureAmount,
      seconds,
      formattedDuration: fromSeconds(seconds),
      modules,
    };
  }, [json_curriculum]);

  return (
    <>
      <Layout
        seoProps={{
          title,
          description: subtitle,
          image: thumbnailURL,
          pathname: `/curso/${slug}/treinamento`,
        }}
        headerOptions={{
          fixed: false,
          hasOptions: false,
        }}
      >
        <Container>
          <CourseSection>
            <PresentationContainer>
              <CoursePresentation>
                <h1>{title}</h1>
                <h2>{subtitle}</h2>
              </CoursePresentation>
            </PresentationContainer>
          </CourseSection>

          <CourseInfo>
            <div className="wrapper">
              <CoursePreview
                price={formattedPrice}
                redirect={redirect}
                thumbnail={thumbnailURL}
                includes={includes}
                warranty={warranty}
              />
              <InfoContainer className="desktop-to--left">
                <LearnContainer learnings={learnings} />

                <RequirementsContainer requirements={requirements} />

                <DescriptionContainer video={video} description={description} />

                <CurriculumContainer curriculum={curriculum} />

                <WarrantyContainer>
                  <img src="/images/img-garantia.png" alt="Garantia" />
                  <div className="warranty-info">
                    <h4>Risco Zero!</h4>
                    <h5>
                    Se em até 15 dias depois da sua compra, por qualquer motivo ele não atender suas expectativas, basta entrar em contato com a nossa equipe que iremos reembolsar 100% do seu investimento. Isso mesmo, você não corre risco nenhum!
                    </h5>
                  </div>
                </WarrantyContainer>

                <CertifiedContainer>
                  <div className="certified-info">
                    <h4>Certificado de Conclusão</h4>
                    <h5>
                    Ao completar 75% do conteúdo, você poderá emitir nosso Certificado de Conclusão do Treinamento com seu nome e carga horária, direto na área de membros, sem burocracias.
                    </h5>
                  </div>
                    <img src="/images/img-certificado.png" alt="Certificado" />
                </CertifiedContainer>

                <TestimonyContainer>
                  <div className="testimony-info">
                    <h4>
                      Veja o que nossos alunos têm a dizer sobre nossos
                      treinamentos, materiais e conteúdos:
                    </h4>
                    <img
                      className="img-testmony"
                      src="/images/novos-depoimentos/depoimento 1.png"
                      alt="depoimento afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/novos-depoimentos/depoimento 2.png"
                      alt="depoimento afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/novos-depoimentos/depoimento 4.jpeg"
                      alt="depoimento afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/depoimento adamir afiliart.jpeg "
                      alt="depoimento may pinheiro afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/novos-depoimentos/depoimento 5.jpeg"
                      alt="depoimento afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/depoimento infinita afiliart.jpeg "
                      alt="depoimento afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/novos-depoimentos/depoimento 3.png"
                      alt="depoimento afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/novos-depoimentos/depoimento 6.jpg"
                      alt="depoimento afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/novos-depoimentos/depoimento 7.jpg"
                      alt="depoimento afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/novos-depoimentos/depoimento 8.jpg"
                      alt="depoimento afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/novos-depoimentos/depoimento 9.jpg"
                      alt="depoimento afiliart"
                    />
                    {/* <img
                      className="img-testmony"
                      src="/images/depoimento liza.png "
                      alt="depoimento afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/depoimento empreende (1).png "
                      alt="depoimento empreende afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/depoimento may pinheiro.png "
                      alt="depoimento may pinheiro afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/depoimento marina brandão afiliart.png "
                      alt="depoimento may pinheiro afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/depoimento rebeca mello afiliart.jpeg "
                      alt="depoimento rebeca afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/depoimento viktor afiliart.jpeg "
                      alt="depoimento afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/depoimento rafael gabriel.jpeg "
                      alt="depoimento afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/depoimento adamir afiliart.jpeg "
                      alt="depoimento may pinheiro afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/depoimento 10 afiliart.jpeg "
                      alt="depoimento rebeca afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/depoimento damiao afiliart.jpeg "
                      alt="depoimento afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/depoimento infinita afiliart.jpeg "
                      alt="depoimento afiliart"
                    />
                    <img
                      className="img-testmony"
                      src="/images/depoimento wellington jorge.jpeg "
                      alt="depoimento afiliart"
                    /> */}
                  </div>
                </TestimonyContainer>
              </InfoContainer>
            </div>
          </CourseInfo>
        </Container>
      </Layout>
      <GlobalStyle />
    </>
  );
}

/**
 * NÃO MUDAR
 */
export const query = graphql`
  query($slug: String!) {
    course: contentfulCourse(slug: { eq: $slug }) {
      id
      title
      subtitle
      price
      video
      learnings
      requirements
      warranty
      thumbnail {
        file {
          url
        }
        fluid {
          srcSet
        }
      }
      redirect {
        internal {
          content
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      curriculum {
        internal {
          content
        }
      }
      includes {
        internal {
          content
        }
      }
    }
  }
`;
