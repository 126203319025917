import styled from 'styled-components';

import responsive from '../../styles/responsive';
import colors from '../../styles/colors';

const logo = '/images/logo.svg';

export const Header = styled.header`
  background: ${colors.headerDefault};

  padding: 10px 0;

  width: 100%;
  top: 0;
  left: 0;
  position: ${props => (props.fixed ? 'fixed' : 'absolute')};

  transition: 0.2s;

  ${responsive.query(responsive.desktopMin)} {
    background: none;
  }

  ${props =>
    props.scrolled && `background: ${colors.headerScrolling} !important;`}
`;

export const Navbar = styled.nav`
  margin: 0 auto;
  padding: 0 5%;

  display: flex;
  flex-direction: column;

  ${responsive.query(responsive.desktopMin)} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const NavbarWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: ${({ centered }) => (centered ? 'center' : 'space-between')};
`;

export const Logo = styled.img.attrs({
  src: logo,
  alt: 'Home',
})`
  width: 120px;
  flex-shrink: 0;

  margin-right: 20px;
`;

export const ToggleMenuButton = styled.button`
  background: none;
  border: none;

  ${responsive.query(responsive.desktopMin)} {
    display: none;
  }
`;

export const Items = styled.ul`
  ${props => (props.toggle ? 'display: flex;' : 'display: none;')}
  flex-direction: inherit;

  ${responsive.query(responsive.desktopMin)} {
    display: flex;
  }
`;

export const Item = styled.li`
  padding: 8px;
  width: max-content;

  a {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }

  ${responsive.query(responsive.desktopMin)} {
    &.header-button {
      background: #de2540;
      border-radius: 4px;
    }
  }
`;
