import styled from 'styled-components';

import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

import responsive from '../../styles/responsive';

export default styled(SlideDown)`
  display: flex;
  flex-direction: column;

  ${responsive.query(responsive.desktopMin)} {
    flex-direction: row;
  }
`;
