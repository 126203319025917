import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FaPlayCircle } from 'react-icons/fa';

import colors from '../../../styles/colors';

import {
  Container,
  Header,
  ExpandButton,
  ModuleList,
  ModuleButton,
  Lectures,
} from './styles';

export default function CurriculumContainer({ curriculum }) {
  const [expandAll, setExpandAll] = useState(false);

  const [modules, setModules] = useState(curriculum.modules);

  function toggleExpandAll() {
    setModules(
      modules.map(module => {
        module.expanded = !expandAll;
        return module;
      })
    );
    setExpandAll(!expandAll);
  }

  function toggleExpand(id) {
    setModules(
      modules.map(module => {
        if (module.id !== id) return module;

        module.expanded = !module.expanded;
        return module;
      })
    );
  }

  function renderLecture(lecture) {
    return (
      <li className="lecture" key={lecture.id}>
        <div>
          <span>
            <FaPlayCircle size={20} color={colors.lightGrayText} />
          </span>
          <span className="title">{lecture.title}</span>
        </div>
        <span className="length">{lecture.formattedDuration}</span>
      </li>
    );
  }

  return (
    <Container>
      <Header>
        <span className="title">Conteúdo do curso</span>
        <div>
          <ExpandButton onClick={toggleExpandAll}>
            {expandAll ? 'Minimizar tudo' : 'Expandir tudo'}
          </ExpandButton>
          <span className="total-lectures">
            {curriculum.lectureAmount} aulas
          </span>
          <span className="total-hours">{curriculum.formattedDuration}</span>
        </div>
      </Header>
      <ModuleList>
        {modules.map(module => (
          <li key={module.id}>
            <ModuleButton
              expanded={module.expanded}
              onClick={() => toggleExpand(module.id)}
            >
              <div>
                <span className="title-toggle">
                  {module.expanded ? '-' : '+'}
                </span>
                <span className="title-text">{module.title}</span>
              </div>
              <div>
                <span className="length">{module.formattedDuration}</span>
              </div>
            </ModuleButton>

            <Lectures expanded={module.expanded}>
              <ul>{module.lectures.map(lecture => renderLecture(lecture))}</ul>
            </Lectures>
          </li>
        ))}
      </ModuleList>
    </Container>
  );
}

CurriculumContainer.propTypes = {
  curriculum: PropTypes.shape({
    lectureAmount: PropTypes.number.isRequired,
    seconds: PropTypes.number.isRequired,
    formattedDuration: PropTypes.string.isRequired,
    modules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        formattedDuration: PropTypes.string.isRequired,
        lectures: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            seconds: PropTypes.number.isRequired,
            formattedDuration: PropTypes.string.isRequired,
          })
        ),
      })
    ),
  }).isRequired,
};
